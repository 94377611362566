import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Breadcrumb from './../../component/Breadcrumb';
import Avatar from './../../component/Avatar';
import { makeStyles, Typography, Grid, Chip, Box, Fab, Card, CardContent, Paper, Tabs, Tab, FormControl, InputLabel, Select, MenuItem, Input, CardActions, Button, LinearProgress, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import axios from 'axios';
import { wordpressBaseUrl } from 'settings';
import PhotoView from 'views/PhotoView/PhotoView';
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import ModalAssignEditor from './ModalAssignEditor';
import ModalConfirmation from 'component/_hh/ModalConfirmation';
import { ExpandMore, TrainRounded } from '@material-ui/icons';
import { getDefaultClientSideOptions } from 'utils/muiDatatable';
import { parseAndFormatDateTime } from 'utils/date';
import StyleSelectorBackground from 'component/_hh/StyleSelectorBackground';
import CommentsView from 'component/_hh/CommentsView';
import autobahn from 'autobahn';
import ModalRequestResubmit from './ModalRequestResubmit';
import QualityPreferencesIcon from '../../assets/images/team-preferences/star.svg';
import EaseOfUsePreferencesIcon from '../../assets/images/team-preferences/magicpen.svg';
import { rgbaToHex } from 'views/Utils/helpers';
import ExpeditedIndividualDropdown from './ExpeditedIndividualDropdown';
import ReviewerDropdown from './ReviewerDropdown';
import HoldModal from './HoldModal';
import ProductionUpdates from './ProductionUpdates.js/ProductionUpdates';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    overlaymain: {
        position: 'relative',
        display: 'block',
        margin: '0px',
        '&:hover > span': {
            display: 'flex',
        },
    },
    btnlast: {
        marginLeft: '5px',
    },
    userlisttable: {
        background: 'transparent',
        boxShadow: 'none',
        '& table': {
            borderSpacing: '0 10px',
            borderCollapse: 'separate',
        },
        '& tbody tr': {
            background: '#fff',
            boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.1)',
        },
        '& tbody tr:hover': {
            background: theme.palette.primary.main + '!important',
            '& td': {
                color: '#fff',
            },
        },
    },
    tag: {
        marginRight: 3,
        marginTop: 3
    },
    fieldsHeader: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    flatcardblock: {
        padding: '16px 16px',
        borderLeft: '1px solid ' + theme.palette.background.default,
        borderBottom: '1px solid ' + theme.palette.background.default,
    },
    flatcardblock2: {
        padding: '0px',
        borderLeft: '1px solid ' + theme.palette.background.default,
        borderBottom: '1px solid ' + theme.palette.background.default,
    },
    flatcardbody: {
        padding: '0px !important',
        '& svg': {
            width: '40px',
            height: '40px',
        },
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

function Field({ name, value, fullWidth = false, valueStyles = {} }) {
    return (
        <Grid container spacing={3} style={{ borderBottom: "1px solid #efefef", marginBottom: 10 }}>
            <Grid item xs={12} sm={fullWidth ? 2 : 5}>
                <Typography variant="h6" color="inherit">
                    {name}:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={fullWidth ? 10 : 6}>
                <div style={valueStyles}> {value}</div>
            </Grid>
        </Grid>
    )
}

function LoadingOverlay() {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "120%", position: "absolute", marginTop: -200, marginLeft: -200, backgroundColor: "rgba(255,255,255,0.8)", zIndex: 9999999 }}>
            <CircularProgress />
        </div>
    )
}

function truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
}

function uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function flattenObject(ob) {
    const toReturn = {};

    Object.keys(ob).map(i => {
        if (typeof ob[i] === 'object' && ob[i] !== null) {
            const flatObject = flattenObject(ob[i]);
            Object.keys(flatObject).map(x => {
                toReturn[`${i}.${x}`] = flatObject[x];
                return x;
            });
        } else {
            toReturn[i] = ob[i];
        }
        return i;
    });
    return toReturn;
}

function getLoginUrlWithRedirect(url) {
    return `${wordpressBaseUrl}/wp-login.php?redirect_to=${encodeURIComponent(url)}`;
}

const AssignToEditorView = ({ viewType }) => {
    const history = useHistory();
    document.title = viewType === "assign" ? "Needs to be assigned" : "Needs QA";
    const isAssign = viewType === "assign";
    const isDeliver = viewType === "deliver";
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataForTable, setDataForTable] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedHero, setSelectedHero] = useState();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [redoCount, setRedoCount] = useState(0);
    const [reeditCount, setReeditCount] = useState(0);

    const [showAssignEditorModal, setShowAssignEditorModal] = useState(false);
    const [showRejectConfirmationModal, setShowRejectConfirmationModal] = useState(false);
    const [showApproveConfirmationModal, setShowApproveConfirmationModal] = useState(false);
    const [showRequestResubmitModal, setShowRequestResubmitModal] = useState(false);
    const [showHoldModal, setShowHoldModal] = useState(false);

    const [activeFilters, setActiveFilters] = useState([]);
    const [activeEditorFilter, setActiveEditorFilter] = useState(null);
    const [allEditors, setAllEditors] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [isMounted, setIsMounted] = useState(false);
    const [resubmitClicked, setResubmitClicked] = useState(false);
    const [editorNotes, setEditorNotes] = useState();
    // console.log('tablValue', tabValue, viewType)
    const handleChangeTab = (event, newValue) => {
        if (!getUploadImage(selectedHero.request, "edited_image_path_transparent") && newValue === 3) newValue = 4;
        setTabValue(newValue);
    };

    const handleChangeFilter = (event) => {
        var filters = event.target.value || [];
        setActiveFilters(filters);
    };

    const handleChangeEditorFilter = (event) => {
        var editor = event.target.value || null;
        setActiveEditorFilter(editor);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        console.log('init use effect', isMounted);
        setupWebsocket();
        loadRequests(id);
        setIsMounted(true);

    }, []);

    useEffect(()=>{
        setEditorNotes();
        if (selectedHero?.team?.id) {
            setIsLoading(true);
            axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-team-metadata?team_id=${selectedHero.team.id}&key=editor_notes`).then((response) => {
                if(response?.data?.metadata?.value ){
                    setEditorNotes(response?.data?.metadata?.value);
                }
                setIsLoading(false)
            });
        }
    }, [selectedHero])

    useEffect(() => {
        if (!isMounted) {
            return;
        }
        loadRequests()
    }, [activeFilters, activeEditorFilter]);

    useEffect(()=>{
        let IntervalId;
        if (resubmitClicked) {
            console.log('resubmit clicked');
             IntervalId = setInterval(()=> {
                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get('id');
                loadRequests(id, true);
            }, 10000)
        }
        return ()=>clearInterval(IntervalId);
    }, [resubmitClicked]);

    useEffect(()=>{
        if (resubmitClicked) {
            console.log('resubmit clicked data');
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            const idfound = data.find(item=>item.request.id === id.toString());
            if (!idfound) {
                setResubmitClicked(false);
                setSelectedIndex(null);
                setSelectedHero(null);
            }
        }
    }, [resubmitClicked, data]);

    const setupWebsocket = () => {
        console.log('set up web socket');
        //websocket 
        let connection = new autobahn.Connection({
            url: 'wss://my.headshots.com/socket',
            realm: "SuperCoolRealmName"
        });
        connection.onopen = function (session) {
            session.subscribe("message", (message) => {
                console.log("message", message);
                var data = JSON.parse(message);
                loadRequests(null, true);
            });
        };
        // Open the connection
        connection.open();
    }

    const loadRequests = (autoSelectRequestId = null, websocketUpdate = false) => {
        if(!websocketUpdate){
            setIsLoading(true);
            setSelectedHero(null);
            setSelectedIndex(null);
        }
        
        var statusList = viewType === "assign" ? ["Needs to be assigned", "Rejected By Editor"] : ["Sent back to QA"];
        const availibleTags = ["on hold", "expedite", "vip", "move forward", "expedited qa"];
        const activeFilterTags = activeFilters.filter(tag => availibleTags.includes(tag));
        const availibleFilters = ["individual", "team", 're-edit request', 're-do request', "quality", "ease of use"];
        const filters = activeFilters.filter(tag => availibleFilters.includes(tag));
        const url = `${wordpressBaseUrl}/wp-json/hh-api/get-requests-by-status`;
        let editor_id = null;
        editor_id = activeEditorFilter === 'all' ? null : activeEditorFilter; 
        return axios
            .post(url, {
                statusList: statusList,
                tags: activeFilterTags,
                filters: filters,
                editor_id: editor_id,
            })
            .then((response) => {
                let { data } = response;
                // get first 10 rows 
                //data = data.slice(0, 10);
                // get last 10 rows
                //data = data.slice(Math.max(data.length - 10, 1))

                // sort data, with rows that have data.hero.tags containing "on hold" at the end, and "expidite" at the top
                data.sort((a, b) => {
                    const aherotags = a.hero?.tags?.split(',').map(term=>term.trim());
                    const bherotags = b.hero?.tags?.split(',').map(term=>term.trim());
                    const areqreedit = a.request?.is_hero_requested_re_edit === "1";
                    const breqreedit = b.request?.is_hero_requested_re_edit === "1";
                    const aHasOnHold = aherotags && aherotags.includes("on hold");
                    const bHasOnHold = bherotags && bherotags.includes("on hold");
                    const aHasExpedite = aherotags && aherotags.includes("expedite");
                    const bHasExpedite = bherotags && bherotags.includes("expedite");
                    // const aHasExpeditedQa = aherotags && aherotags.includes("expedited qa");
                    // const bHasExpeditedQa = bherotags && bherotags.includes("expedited qa");
                    const aHasRejected = a.request.status === "Rejected By Editor";
                    const bHasRejected = b.request.status === "Rejected By Editor";
                    // if (aHasExpeditedQa && !bHasExpeditedQa) {
                    //     return -1;
                    // }
                    // if (!aHasExpeditedQa && bHasExpeditedQa) {
                    //     return 1;
                    // }
                    if (aHasRejected && !bHasRejected) {
                        return -1;
                    }
                    if (!aHasRejected && bHasRejected) {
                        return 1;
                    }
                    if (aHasOnHold && !bHasOnHold) {
                        return 1;
                    }
                    if (!aHasOnHold && bHasOnHold) {
                        return -1;
                    }
                    if (aHasExpedite && !bHasExpedite) {
                        return -1;
                    }
                    if (!aHasExpedite && bHasExpedite) {
                        return 1;
                    }
                    if (areqreedit && !breqreedit) {
                        return -1;
                    }
                    if (!areqreedit && breqreedit) {
                        return 1;
                    }
                    return 0;
                });
                const editorIdToCount = {};
                let resubmitCount = 0;
                let reeditCount = 0;
                setData(data.map((item) => {
                    const imageNameAndExtension = item.request.image_path.split('/').pop();
                    item.request.filename = imageNameAndExtension;
                    if (item.request.edited_image_path) {
                        const imageNameAndExtensionEdited = item.request.edited_image_path.split('/').pop();
                        item.request.filename_edited = imageNameAndExtensionEdited;
                    }
                    editorIdToCount[item.editor.id] = (editorIdToCount[item.editor.id] || 0) + 1;

                    if (item.request.parent_request_is_hero_requested_re_edit === "1") {
                        resubmitCount++;
                    }
                    if (item.request.is_hero_requested_re_edit === "1") {
                        reeditCount++;
                    }

                    item.hero.name = item.hero.first_name + " " + item.hero.last_name;

                    return item;
                }));
                setDataForTable(data.map((item) => flattenObject(item)));
                setRedoCount(resubmitCount);
                setReeditCount(reeditCount);

                // if (allEditors.length === 0) {
                    axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-editors`).then(res => {
                        res.data.editors = res.data.editors.map(editor => {
                            editor.count = editorIdToCount[editor.id] || 0;
                            return editor;
                        });
                        setAllEditors(res.data.editors);
                        setIsLoading(false);
                    });
                // }

                if (autoSelectRequestId && !websocketUpdate) {
                    const index = data.findIndex((item) => item.request.id === autoSelectRequestId);
                    if (index !== -1) {
                        setSelectedHero(data[index]);
                        setSelectedIndex(index);
                        var element = document.getElementById(`MUIDataTableBodyRow-${index}`);
                        if (element) {
                            element.scrollIntoView({ block: 'center' });
                        }
                    } else {
                        history.push({
                            pathname: `/production/${viewType}`,
                            search: ``,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const assign = async (editorId) => {
        setIsLoading(true);
        setShowAssignEditorModal(false)
        const url = `${wordpressBaseUrl}/wp-json/hh-api/assign-request-to-editor`;
        const response = await axios.post(url, {
            team_id: selectedHero.team.id,
            editor_id: editorId,
            request_id: selectedHero.request.id,
            hero_id: selectedHero.hero.id
        });

        if (!response.data.success) {
            alert("Error assigning editor");
            console.log(response.data);
            setShowAssignEditorModal(true)
            setIsLoading(false);
        } else {
            return loadRequests();
        }
    }

    const deliver = () => {
        setIsLoading(true);
        setShowApproveConfirmationModal(false)
        const url = `${wordpressBaseUrl}/wp-json/hh-api/deliver-request-to-hero`;
        axios
            .post(url, {
                editor_id: selectedHero.editor.id,
                request_id: selectedHero.request.id,
                qa: "1"
            })
            .then((response) => {
                return loadRequests();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const reject = ({ comment }) => {
        setIsLoading(true);
        setShowRejectConfirmationModal(false);
        const url = `${wordpressBaseUrl}/wp-json/hh-api/deliver-request-to-hero`;
        axios
            .post(url, {
                editor_id: selectedHero.editor.id,
                request_id: selectedHero.request.id,
                qa: "0",
                comment: comment
            })
            .then((response) => {
                return loadRequests();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const updateHold = (hero, value, comment=null, request=null) => {
        setIsLoading(true);
        const url = `${wordpressBaseUrl}/wp-json/hh-api/update-hero`;
        let tags = hero.tags ? `${hero.tags},on hold` : 'on hold';
        if (!value) {
            tags = hero.tags.replace('on hold', '').replace(',,', ',');
            if (tags.endsWith(',')) {
                tags = tags.substring(0, tags.length - 1);
            }
        }
        const inputData = {
            id: hero.id,
            tags: tags,
        };
        if (comment){
            inputData.comment = comment;
        }
        if (request){
            inputData.request_id = request.id;
        }
        axios
            .post(url, inputData)
            .then((response) => {
                setShowHoldModal(false);
                return loadRequests();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }


    const columns = [
        {
            name: 'request.id',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'hero.public_id',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'team.name',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'team.public_id',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'hero.name',
            options: {
                display: "excluded",
            }
        },
        {
            label: 'Hero',
            name: 'hero.id',
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (!data || !data[dataIndex] || !data[dataIndex]["hero"] || !data[dataIndex]["request"]) {
                        return null;
                    }
                    const imageUrl = data[dataIndex]["request"].image_path;
                    const imageNameAndExtension = imageUrl.split('/').pop();
                    const heroTags = data[dataIndex]["hero"].tags?.split(',').map(term => term.trim());
                    const request = data[dataIndex]["request"];
                    return (
                        <Grid key={dataIndex} container spacing={2} alignItems="center" style={{ width: 245, whiteSpace: "pre-wrap" }}>
                            {data[dataIndex]["team"]?.team_preferences && <Grid style={{paddingRight: "0px"}} item><img style={{width: "30px"}} src={data[dataIndex]["team"]?.team_preferences === 'quality' ? QualityPreferencesIcon : EaseOfUsePreferencesIcon} alt="" /></Grid>}
                            <Grid item>
                                <Avatar alt={data[dataIndex]["hero"].public_id}
                                    src={`https://d24j8drerh99og.cloudfront.net/hero/${imageNameAndExtension}?w=80`} />
                            </Grid>
                            <Grid item xs >
                                <Typography align="left" variant="h6" color="inherit">
                                    {data[dataIndex]["hero"].public_id }
                                </Typography>
                               
                                <Box component="span" m={1} className={classes.overlaymain}>
                                    {request.parent_request_is_hero_requested_re_edit === "1" ? <Chip label="Redo" style={{ backgroundColor: "rgb(0, 172, 81)" }} size="small" className={classes.tag} /> : ''}
                                    {request.is_hero_requested_re_edit === "1" ? <Chip label="Re-Edit" style={{ backgroundColor: "rgb(0, 172, 81)" }} size="small" className={classes.tag} /> : ''}
                                    {request.status === "Rejected By Editor" ? <Chip label="Rejected By Editor" style={{ backgroundColor: "rgba(244, 106, 106, 0.93)" }} size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("vip")) ? <Chip label="VIP" color="primary" size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("expedited qa")) ? <Chip label="Expedited QA" style={{ backgroundColor: "#f4a100" }} size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("expedite")) ? <Chip label="Expedite" color="primary" size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("pilot")) ? <Chip label="Pilot" color="primary" size="small" className={classes.tag} /> : ''}
                                    
                                    {(heroTags && heroTags.includes("on hold")) ? <Chip label="On Hold" color="default" size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("test")) ? <Chip label="test" color="default" size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("move forward")) ? <Chip label="Move Forward" color="default" size="small" className={classes.tag} /> : ''}
                                    {(heroTags && heroTags.includes("transparent png")) ? <Chip label="Transparent PNG" color="default" size="small" className={classes.tag} /> : ''}
                                </Box>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            label: 'Team Name',
            name: 'team.name',
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (!data || !data[dataIndex] || !data[dataIndex]["hero"] || !data[dataIndex]["request"]) {
                        return null;
                    }
                    const team = data[dataIndex]["team"];

                    return <><Typography align="center" variant="body" color="inherit">{truncate(team.name || "", 20)}</Typography>
                    <Typography align="left" style={{fontSize: "12px"}} variant="h6" color="inherit">{"Req: "+data[dataIndex]["request"].id}</Typography></>;
                }
            },
        },
        {
            label: "Created",
            name: 'request.created_at',
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (!data || !data[dataIndex] || !data[dataIndex]["hero"] || !data[dataIndex]["request"]) {
                        return null;
                    }
                    const request = data[dataIndex]["request"];

                    return <div style={{whiteSpace: "wrap", width: "90px", textAlign: "center"}}>{parseAndFormatDateTime(request.created_at)}</div>;
                }
            },
        },
    ];

    const options = getDefaultClientSideOptions(data.length, isLoading, null, {
        filter: false,
        filterType: 'dropdown',
        responsive: 'vertical',
        tableBodyMaxHeight: '70vh',
        onColumnSortChange: (changedColumn, direction) => console.log('changedColumn: ', changedColumn, 'direction: ', direction),
        onChangeRowsPerPage: (numberOfRows) => console.log('numberOfRows: ', numberOfRows),
        onChangePage: (currentPage) => console.log('currentPage: ', currentPage),
        // show all pages
        count: dataForTable.length,
        rowsPerPage: 10000,
        // not selectable
        selectableRows: 'single',
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            if (currentRowsSelected.length > 0) {
                setSelectedHero(data[currentRowsSelected[0].dataIndex]);
                console.log('data[]', data[currentRowsSelected[0].dataIndex])
                setSelectedIndex(currentRowsSelected[0].dataIndex);
                let pathnam = `/production/${viewType}`;
                const currentPath = window.location.pathname;
                if (currentPath.endsWith('/')) {
                    pathnam = `/production/${viewType}/`;
                }
                history.push({
                    pathname: pathnam,
                    search: `?id=${data[currentRowsSelected[0].dataIndex].request.id}`,
                });
            }
        },
        rowsSelected: selectedHero ? [selectedIndex] : [],
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (<>
                <div style={{ margin: 20, float: 'right' }}>{`${count} Total`}</div>
                {/* <div style={{ margin: 20, float: 'right' }}>{`${redoCount} Redos`}</div>
                <div style={{ margin: 20, float: 'right' }}>{`${reeditCount} Re-edits`}</div> */}
            </>)
        },
        customSearch(searchQuery, currentRowArray, columns) {
            for (let i = 0; i < currentRowArray.length; i++) {
                const currentRow = currentRowArray[i];
                if (currentRow && String(currentRow).trim().toLowerCase().includes(searchQuery.trim().toLowerCase())) {
                    return true;
                }
            }
            return false;
        }
    });

    const getUploadImage = (request, type) => {
        if (request.uploads) {
            let transparent = null;
            request.uploads.map(upload=>{
                if (upload.type === type ) transparent = upload.path;
            })
            // console.log('geting upload first ', transparent);
            if (transparent) return transparent.substring(transparent.lastIndexOf('/') + 1);
        }
        return false;
    }

    const getLastUpload = (request, type) => {
        if (request.uploads) {
            let lastUpload = null;
            request.uploads.map(upload=>{
                if (upload.type === type) lastUpload = upload;
            })
            if (lastUpload) return lastUpload;
        }
        return false;
    }

    const getGuidelines = (guidelineStr) => {
        let guideline = <a href='https://www.dropbox.com/s/tz9cm2m0ucxajj2/Frontify%20Guidelines.png?raw=1'>https://www.dropbox.com/s/tz9cm2m0ucxajj2/Frontify%20Guidelines.png?raw=1</a>;
        if (!guidelineStr) return guideline;
        try{
            const guidelineObj = JSON.parse(guidelineStr);
            if (guidelineObj.guidelines) guideline =  <a href={guidelineObj.guidelines}>{guidelineObj.guidelines}</a>;
            if (guidelineObj.additional_guidelines) guideline =  <>{guidelineObj.guidelines ? <>{guideline}<br/></>: ''}<a href={guidelineObj.additional_guidelines}>{guidelineObj.additional_guidelines}</a></>;
        } catch (e) {console.log('error', e)}
        return guideline;
    }

    const deleteRequest = async(selectedHero) => {
        let confirmDelete = window.confirm("Are you sure?")
        if (confirmDelete) {
            // console.log('requestId', props.request.id);
            const url = `${wordpressBaseUrl}/wp-json/hh-api/delete-hero-request?new_photo_request=true`;
            const res = await axios.post(url, {
                request_id: selectedHero.request.id,
            });
            console.log('res', res)
            if (res.data.success) {
                window.location.reload();
            } else {
                alert(res.data.message)
            }
            // handleClose();
        }
    }
    
    return (
        <React.Fragment>
            <Breadcrumb title={viewType === "assign" ? "Needs to be assigned" : "Needs QA"}>
                <Typography variant="subtitle2" color="inherit" className="link-breadcrumb">
                    Production Manager
                </Typography>
                <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
                    {uppercaseFirstLetter(viewType)}
                </Typography>
            </Breadcrumb>
            <div>
                {isLoading ? <LoadingOverlay /> : null}
                <ProductionUpdates />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={3} xs={6}>
                                <Card>
                                    <CardContent className={classes.flatcardbody}>
                                        <Grid item xs={12} sm={12} className={classes.flatcardblock2}>
                                            <ExpeditedIndividualDropdown />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={6}>
                                <Card>
                                    <CardContent className={classes.flatcardbody}>
                                        <Grid item xs={12} sm={12} className={classes.flatcardblock2}>
                                            <ReviewerDropdown />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={3} xs={4}>
                                <Card>
                                    <CardContent className={classes.flatcardbody}>
                                        <Grid item xs={12} sm={12} className={classes.flatcardblock}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h5" className={classes.textsuccess} align="right">
                                                        {reeditCount}
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" align="left">
                                                        Re-edits
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={4}>
                                <Card>
                                    <CardContent className={classes.flatcardbody}>
                                        <Grid item xs={12} sm={12} className={classes.flatcardblock}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h5" className={classes.textsuccess} align="right">
                                                        {redoCount}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" align="left">
                                                        Redos
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={4}>
                                <Card>
                                    <CardContent className={classes.flatcardbody}>
                                        <Grid item xs={12} sm={12} className={classes.flatcardblock}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h5" className={classes.textsuccess} align="right">
                                                        {data.length}
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" align="left">
                                                        Total
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div style={{}}>
                            <MUIDataTable title={
                                <>
                                    <FormControl style={{ width: "50%", marginLeft: -22 }}>
                                        <InputLabel id="demo-mutiple-chip-label">Filters</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-chip-label"
                                            id="demo-mutiple-chip-tag-filters"
                                            multiple
                                            value={activeFilters}
                                            onChange={handleChangeFilter}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {activeFilters.map((value) => (
                                                        <React.Fragment key={value}>
                                                            <Chip key={value} label={value} className={classes.tag} size="small" />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {['expedite', 'expedited qa', 'vip', 'on hold', 'move forward', 're-edit request', 're-do request', 'individual', 'team', 'quality', 'ease of use'].map((name) => (
                                                <MenuItem key={name} value={name} >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl style={{ width: "50%", marginLeft: 6 }}>
                                        <InputLabel id="demo-mutiple-chip-label">Filter Editor</InputLabel>
                                        <Select
                                            labelId="editor-filter-label"
                                            id="editor-filter"
                                            value={activeEditorFilter}
                                            onChange={handleChangeEditorFilter}
                                            input={<Input id="editor-filter-input" />}
                                            renderValue={(selected) => {
                                                return (<div>
                                                    {
                                                        selected === 'all' ? "All" : allEditors.find(editor => editor.id === selected)?.name
                                                    }
                                                </div>)
                                            }}
                                        >
                                            {[{ name: "All", id: 'all' }, ...allEditors].map((editor) => (
                                                <MenuItem key={editor.id} value={editor.id} >
                                                    {editor.name} ({editor.count || ""})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                            }
                                data={dataForTable}
                                columns={columns}
                                options={options}
                                className={classes.userlisttable} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <div style={{ marginTop: 73 }}>
                            <Paper square className={classes.root}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChangeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="icon tabs example"
                                >
                                    {viewType !== "assign" ? <Tab icon={<PersonIcon />} label="Details" /> : ''}
                                    <Tab icon={<ImageIcon />} label="Original Image" />
                                    {selectedHero && selectedHero.request.filename_edited && <Tab icon={<ImageIcon />} label="Edited" />}
                                    {(selectedHero && getUploadImage(selectedHero.request, "edited_image_path_transparent")) && <Tab icon={<ImageIcon />} label="Transparent" />}
                                    {(selectedHero && getUploadImage(selectedHero.request, "edited_image_path_additional")) && <Tab icon={<ImageIcon />} label="Additional" />}
                                </Tabs>
                            </Paper>
                            {viewType !== "assign" ? <TabPanel value={tabValue} index={0} >
                                {selectedHero && <Card>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <h3 className={classes.fieldsHeader}>Hero</h3>
                                                <Field name="HHID" value={<a href={`/heroes/detail/${selectedHero.hero.id}`}>{selectedHero.hero.public_id}</a>} />
                                                <Field name="Request ID" value={selectedHero.request.id} />
                                                <Field name="Name" value={`${selectedHero.hero.first_name} ${selectedHero.hero.last_name}`} />
                                                <Field name="Resubmit Count" value={selectedHero.request.resubmit_count} />
                                                {(selectedHero.request.parent_request_id || selectedHero.request.is_hero_requested_re_edit === "1" || selectedHero.request.due_date) &&
                                                    <Field name="Estimated Delivery Date" value={selectedHero.request.due_date} />
                                                }
                                                <br></br>
                                                {/* <h3 className={classes.fieldsHeader}></h3> */}


                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <h3 className={classes.fieldsHeader}>Team</h3>
                                                <Field name="Team" value={<a href={`/teams/detail/${selectedHero.team.public_id}`}>{selectedHero.team.name}</a>} />
                                                {selectedHero.team.guidelines && <Field name="Team Guidelines" value={getGuidelines(selectedHero.team.guidelines)} />}
                                                {isDeliver && selectedHero.team.delivery_notes && <Field name="Team Delivery Notes" value={selectedHero.team.delivery_notes} />}

                                                {isAssign && selectedHero.editor.name && <Field name="Editor" value={
                                                    <>
                                                    <a href={`/editors/detail/${selectedHero.editor.id}`}>{selectedHero.editor.name}</a>
                                                    <br/>
                                                    <span style={{fontSize: 12, color: "#999"}}>Currently assigned: {allEditors.find(e => e.id === selectedHero.editor.id)?.request_count || 0}</span>
                                                    </>
                                                } />}
                                                {isDeliver && selectedHero.editor.name && <Field name="Assigned Editor" value={
                                                    <>
                                                    <a href={`/editors/detail/${selectedHero.editor.id}`}>{selectedHero.editor.name}</a>
                                                    <br/>
                                                    <span style={{fontSize: 12, color: "#999"}}>Currently assigned: {allEditors.find(e => e.id === selectedHero.editor.id)?.request_count || 0}</span>
                                                    </>
                                                } />}
                                                <Field name="Assigned On" value={parseAndFormatDateTime(selectedHero?.request?.editor_assigned_date)} valueStyles={{ whiteSpace: "nowrap" }} />
                                                {isDeliver && <Field name="Editor Submit" value={parseAndFormatDateTime(getLastUpload(selectedHero?.request, "edited_image_path")?.created_at)} valueStyles={{ whiteSpace: "nowrap" }} />}

                                                {selectedHero?.team?.team_preferences && <Field name="Quality Check Preferences" value={selectedHero?.team?.team_preferences} valueStyles={{ whiteSpace: "nowrap" }} />}

                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                {selectedHero.team.notes && <Field name="Team Notes" value={selectedHero.team.notes} fullWidth={true} valueStyles={{ paddingLeft: 20 }} />}
                                                {editorNotes && <Field name="Editor Notes" value={editorNotes} fullWidth={true} valueStyles={{ paddingLeft: 20 }} />}
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>Background</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} >
                                                                <StyleSelectorBackground request={selectedHero.request} />
                                                                {(!selectedHero.request.background_url && selectedHero.request.background_color) ? 
                                                                <Field name="Background Color" value={rgbaToHex(selectedHero.request.background_color)} /> 
                                                                : <Field name="Background Name" value={selectedHero.request.background_name} />}
                                                                
                                                                <Field name="Shape Value" value={selectedHero.request.shape_value} />
                                                                <Field name="Outline" value={selectedHero.request.is_outline === "1" ? 'Yes' : 'No'} />
                                                                <Field name="Color Correction" value={selectedHero.request.color_correction_value || "Normal"} />
                                                            </Grid>  </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                        aria-controls="panel1a-content"
                                                        id="comments"
                                                    >
                                                        <Typography className={classes.heading}>Comments ({selectedHero.request.comments.length || 0})</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} >
                                                                <CommentsView comments={selectedHero.request.comments} />
                                                            </Grid>  </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                    <CardActions>
                                        {isAssign && <React.Fragment>
                                            <Button size="medium"
                                                variant='contained'
                                                color="primary"
                                                onClick={() => setShowAssignEditorModal(true)}>
                                                Assign Editor
                                            </Button>
                                            <Button size="medium"
                                                color="primary"
                                                onClick={() => setShowRequestResubmitModal(true)}
                                                >
                                                Request Resubmit
                                            </Button>

                                        </React.Fragment>}
                                        {isDeliver && <React.Fragment>
                                            <Button size="medium"
                                                variant='contained'
                                                color="primary"
                                                onClick={() => setShowApproveConfirmationModal(true)}>
                                                Approve
                                            </Button>
                                            <Button size="medium"
                                                color="primary"
                                                onClick={() => setShowRejectConfirmationModal(true)}>
                                                Reject
                                            </Button>
                                        </React.Fragment>
                                        }

                                        {selectedHero.hero.tags && selectedHero.hero.tags.includes('on hold') ?
                                            <Button size="medium"
                                                color="primary"
                                                onClick={() => updateHold(selectedHero.hero, false)}>
                                                Remove Hold
                                            </Button>
                                            : <Button size="medium"
                                                color="primary"
                                                onClick={() => setShowHoldModal(true)}>
                                                Put on Hold
                                            </Button>}

                                        {/* Button that links to '/teams/compare-heroes-photos/HHT-35044081' */}
                                        <Button size="medium"
                                            color="primary"
                                            as="a"
                                            target="_blank"
                                            href={`/teams/compare-heroes-photos/${selectedHero.team.public_id ?? selectedHero.hero.public_id}`}>
                                            Compare Photos
                                        </Button>
                                        {selectedHero.hero.tags && selectedHero.hero.tags.includes('test') &&
                                            <Button size="medium"
                                                color="secondary"
                                                style={{color: "red"}}
                                                onClick={() => deleteRequest(selectedHero)}>
                                                Delete
                                            </Button>}
                                    </CardActions>

                                </Card>}
                                {!selectedHero && <div style={{ marginTop: "25%" }}>
                                    <center>
                                        <p>Select a hero</p>
                                    </center></div>}
                            </TabPanel>: ''}
                            <TabPanel value={tabValue} index={viewType === 'assign' ? 0 : 1} >
                                <div style={{ margin: 0, border: "1px solid rgba(0, 0, 0, 0.06)", padding: 20 }}>
                                    {selectedHero && (tabValue === 0 && viewType === 'assign' || tabValue === 1) &&
                                        <><PhotoView
                                            key={selectedHero.hero.public_id}
                                            hero_public_id={selectedHero.hero.public_id}
                                            filename={selectedHero.request.filename}
                                            selectedHero={selectedHero}
                                            setShowHoldModal={setShowHoldModal}
                                            assignView={viewType === 'assign'}
                                            setShowAssignEditorModal={setShowAssignEditorModal} 
                                            setShowRequestResubmitModal={setShowRequestResubmitModal} 
                                            updateHold={updateHold}
                                            deleteRequest={deleteRequest}
                                            />
                                            </>}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2} >
                                <div style={{ margin: 0, border: "1px solid rgba(0, 0, 0, 0.06)", padding: 20 }}>
                                    {selectedHero && tabValue == 2 && selectedHero.request.filename_edited &&
                                        <PhotoView
                                            key={selectedHero.hero.public_id}
                                            hero_public_id={selectedHero.hero.public_id}
                                            filename={selectedHero.request.filename_edited} />}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3} >
                                <div style={{ margin: 0, border: "1px solid rgba(0, 0, 0, 0.06)", padding: 20 }}>
                                    {selectedHero && tabValue == 3 && getUploadImage(selectedHero.request, "edited_image_path_transparent") &&
                                        <PhotoView
                                            key={selectedHero.hero.public_id}
                                            hero_public_id={selectedHero.hero.public_id}
                                            filename={getUploadImage(selectedHero.request, "edited_image_path_transparent")} />}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4} >
                                <div style={{ margin: 0, border: "1px solid rgba(0, 0, 0, 0.06)", padding: 20 }}>
                                    {selectedHero && tabValue == 4 && getUploadImage(selectedHero.request, "edited_image_path_additional") &&
                                        <PhotoView
                                            key={selectedHero.hero.public_id}
                                            hero_public_id={selectedHero.hero.public_id}
                                            filename={getUploadImage(selectedHero.request, "edited_image_path_additional")} />}
                                </div>
                            </TabPanel>
                        </div>
                    </Grid>
                </Grid>
            </div>


            {selectedHero &&
                <ModalAssignEditor
                    editors={allEditors}
                    team={selectedHero.team}
                    hero={selectedHero.hero}
                    request={selectedHero.request}
                    open={showAssignEditorModal}
                    onClose={() => setShowAssignEditorModal(false)}
                    onSubmit={assign} />}
            {isDeliver && selectedHero &&
                <ModalConfirmation
                    open={showRejectConfirmationModal}
                    onClose={() => setShowRejectConfirmationModal(false)}
                    onSubmit={reject}
                    showCommentInput={true}
                    message="Are you sure you want to reject this submission?" />}
            {isDeliver && selectedHero &&
                <ModalConfirmation
                    open={showApproveConfirmationModal}
                    onClose={() => setShowApproveConfirmationModal(false)}
                    onSubmit={deliver}
                    message="Are you sure you want to approve this submission?" />}
            {selectedHero &&
                <ModalRequestResubmit
                    hero={selectedHero.hero}
                    request={selectedHero.request}
                    team={selectedHero.team}
                    open={showRequestResubmitModal}
                    onClose={() => {setShowRequestResubmitModal(false); loadRequests();}} />}
            {selectedHero && <HoldModal selectedHero={selectedHero} handleSubmit={updateHold} open={showHoldModal} onClose={()=>{setShowHoldModal(false)}}/>}
        </React.Fragment>
    );
};

export default AssignToEditorView;
